import React, { useState } from 'react';
import { css } from '@emotion/core';
import '../styles/progress.scss';

const Step = ({ id, onClick, children, cur }) => (
  <li
    className={`${cur === id || cur > id ? 'active' : ''} ${
      cur > id ? 'done' : ''
    }`}
    onClick={() => id < cur && onClick(id)}
  >
    {children}
  </li>
);

export default () => {
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(c => c + 1);
  const prev = () => setCurrent(c => c - 1);

  const steps = [
    {
      title: 'Land',
      content: <h1>Wünsche</h1>,
    },
    {
      title: 'Aktivitäten',
      content: <h1>Wünsche</h1>,
    },
    {
      title: 'Wünsche',
      content: <h1>Wünsche</h1>,
    },
    {
      title: 'Infos',
      content: <h1>Infos</h1>,
    },
  ];

  return (
    <>
      <div className="stepContainer">
        <ul className="progressbar">
          {steps.map((t, i) => (
            <Step key={i} id={i} cur={current} onClick={setCurrent}>
              <b>{t.title}</b>
            </Step>
          ))}
        </ul>
      </div>
      <div
        css={css`
          width: 80%;
          margin: 0 auto;
        `}
      >
        <p className="title">Schritt {current + 1}: </p>
        <div className="box">
          <fieldset>
            {steps[current].content}
            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                {current < steps.length - 1 && (
                  <button
                    type="button"
                    className="button is-info"
                    onClick={next}
                  >
                    Weiter
                  </button>
                )}
              </p>
              <p className="control">
                <button
                  type="button"
                  className="button is-light"
                  onClick={prev}
                  disabled={current < 1}
                >
                  Zurück
                </button>
              </p>
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};
