import React from 'react';
import { css } from '@emotion/core';
import Stepped from '../components/Stepped';
import Layout from '../components/Layout';

export default () => (
  <Layout>
    <div
      className="container"
      css={css`
        margin-top: 1rem;
        margin-bottom: 3rem;
      `}
    >
      <Stepped />
    </div>
  </Layout>
);
